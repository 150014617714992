<template>
    <div v-if="user">
        <div class="my_personal" ref="personalTab">
            <!--<div class="item">
                <div class="label">
                    Идентификатор:
                </div>
                <div class="val">
                    {{ user.id }}
                </div>
            </div>-->
            <div class="item">
                <a     
                    ref="telegramButton" 
                    :href="tgBotLink"
                    target="_blank">
                    <a-button 
                        type="ui"
                        class="flex items-center text-sm">
                        <span class="h-4 w-4 mr-3">
                            <img class="h-full" src="@/assets/images/telegram.svg" />
                        </span>
                        Уведомления через Telegram
                    </a-button>
                </a>
            </div>
            <a-form-model 
                ref="profileForm" 
                :model="form" 
                :rules="rules">
                <a-form-model-item ref="last_name" label="Фамилия" prop="last_name">
                    <a-input v-model="form.last_name" size="large" />
                </a-form-model-item>
                <a-form-model-item ref="first_name" label="Имя" prop="first_name">
                    <a-input v-model="form.first_name" size="large" />
                </a-form-model-item>
                <a-form-model-item ref="middle_name" label="Отчество" prop="middle_name">
                    <a-input v-model="form.middle_name" size="large" />
                </a-form-model-item>
                <a-form-model-item ref="job_title" label="Должность" prop="job_title">
                    <a-input v-model="form.job_title" size="large" />
                </a-form-model-item>
                <a-form-model-item ref="contact_phone" label="Контактный телефон" prop="contact_phone">
                    <a-input v-model="form.contact_phone" size="large" />
                </a-form-model-item>
                <a-form-model-item ref="birthday" label="Дата рождения" prop="birthday">
                    <a-date-picker 
                        v-model="form.birthday" 
                        :showToday="false"
                        :getCalendarContainer="getCalendarContainer"
                        placeholder="Выбрать дату"
                        dropdownClassName="birthday_select"
                        format="DD.MM.YYYY"
                        size="large" 
                        style="width: 100%;"
                        @openChange="dateOpenChange" />
                </a-form-model-item>
                <a-form-model-item ref="is_make_events_in_task_automatically" label="" prop="is_make_events_in_task_automatically">
                    <a-checkbox :checked="form.is_make_events_in_task_automatically" class="event_auto_check" @change="changeEventAuto">
                        Создавать для меня события автоматически при наличии дедлайнов в задачах
                    </a-checkbox>
                </a-form-model-item>
                <a-button type="primary" :loading="loading" block size="large" @click="formSubmit()">
                    Сохранить
                </a-button>
            </a-form-model>
            <div 
                v-if="recBlock"
                class="recommendations_block">
                <h4>Рекомендации</h4>
                <div class="list">
                    <div v-if="!user.last_name" class="item">
                        <i class="fi fi-rr-cross-small"></i> Заполните вашу фамилию
                    </div>
                    <div v-if="!user.first_name" class="item">
                        <i class="fi fi-rr-cross-small"></i> Заполните ваше имя
                    </div>
                    <div v-if="!user.middle_name" class="item">
                        <i class="fi fi-rr-cross-small"></i> Заполните ваше отчество
                    </div>
                    <div v-if="!user.job_title" class="item">
                        <i class="fi fi-rr-cross-small"></i> Заполните должность
                    </div>
                    <div v-if="!user.birthday" class="item">
                        <i class="fi fi-rr-cross-small"></i> Заполните дату рождения
                    </div>
                    <div v-if="!user.avatar" class="item">
                        <i class="fi fi-rr-cross-small"></i> Загрузите аватар
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import IMask from 'imask'
export default {
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        recBlock() {
            if(this.user) {
                if(!this.user.last_name || !this.user.first_name || !this.user.middle_name || !this.user.avatar || !this.user.job_title || !this.user.birthday) {
                    return true
                } else
                    return false
            } else
                return false
        },

        tgBotURL() {
            return this.$store?.state?.config?.config?.tg_bot_settings?.url
        },
        tgBotLink() {
            const telegramBotLink = `${this.tgBotURL}?start=${this.user.telegram_connect_token}`
            return telegramBotLink
        },
    },
    data() {
        return {
            loading: false,
            form: null,
            mask: '00.00.0000',
            rules: {
                last_name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' },
                ],
                first_name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' },
                ]
            }
        }
    },
    created() {
        this.form = {
            ...this.user,
            job_title: this.user.job_title || '',
            contact_phone: this.user.contact_phone || '',
            birthday: this.user.birthday ? this.$moment(this.user.birthday) : null
        }
    },
    methods: {
        changeEventAuto() {
            this.form.is_make_events_in_task_automatically = !this.form.is_make_events_in_task_automatically
        },
        dateOpenChange(status) {
            this.$nextTick(() => {
                let mask = null
                if(status) {
                    this.$nextTick(() => {
                        const input = document.querySelector('.birthday_select .ant-calendar-input')
                        if(input) {
                            mask = IMask(input, {
                                mask: this.mask
                            })
                        }
                    })
                } else
                    mask = null
            })
        },
        getCalendarContainer() {
            return this.$refs['personalTab']
        },
        async formSubmit() {
            this.$refs.profileForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const userForm = {...this.form}
                        if(userForm.birthday) {
                            userForm.birthday = this.$moment(userForm.birthday).format('YYYY-MM-DD')
                        }
                        const { data } = await this.$http.put('users/update_profile/', userForm)
                        if(data) {
                            localStorage.setItem('user', JSON.stringify(data))
                            this.$store.commit('user/SET_USER', data)
                            this.$message.success('Данные обновлены')
                        }
                    } catch(error) {
                        console.log(error)
                        if(error.message) {
                            this.$message.error(error.message, 4)
                        }
                        if(!error.message) {
                            this.$message.error(JSON.stringify(error), 4)
                        }
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.event_auto_check{
    display: flex;
    align-items: center;
    &::v-deep{
        .ant-checkbox + span{
            line-height: 22px;
        }
    }
}
.my_personal{
    .item{
        // display: flex;
        &:not(:last-child){
            margin-bottom: 15px;
        }
        .label{
            min-width: 200px;
            font-weight: 700;
        }
    }
}
.recommendations_block{
    background-color: #eff2f5;
    border-radius: var(--borderRadius);
    padding: 15px;
    margin-top: 30px;
    h4{
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 600;
    }
    .list{
        .item{
            display: flex;
            align-items: center;
            i{
                font-size: 18px;
                margin-right: 3px;
                color: var(--red);
            }
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}
</style>